$ui-base-color: #072a62;
$ui-primary-color: #b8cef0;
$ui-secondary-color: #1e51a2;
$ui-highlight-color: #e51010;

$simple-background-color: #ffffff;
$ui-base-lighter-color: #ffffff;
$primary-text-color: #ffffff;

@import 'application';
